<template>
    <vs-row>
        <vs-col align="center">
            <div class="module no-bg">
            <form class="center module box-shadow content-inputs" @submit.prevent="sendMail">
                <h1>Neem contact met ons op!</h1><br>
                <vs-input color="#195BFF" label-placeholder="Naam" v-model="mailContent.fullName" required>
                    <template #icon>
                        <box-icon type='solid' name='user-account'></box-icon>                            
                    </template>
                </vs-input>
                <vs-input color="#195BFF" label-placeholder="Email" v-model="mailContent.email"  type="email" name="" id="" required>
                        <template #icon>
                        <box-icon name='mail-send' ></box-icon>  
                        </template>

                </vs-input>
                <vs-input color="#195BFF" label-placeholder="Nummer" v-model="mailContent.phone"  type="text" name="" id="">
                    <template #icon>
                        <box-icon type='solid' name='phone'></box-icon>
                        </template>
                
                </vs-input>  
                <vs-input color="#195BFF" label-placeholder="Onderwerp" v-model="mailContent.subject"  type="text" name="" id="">
                    <template v-slot:icon>
                        <box-icon name='library'></box-icon>
                    </template>
                
                </vs-input><br>
                
                <textarea class="vs-input" placeholder="Bericht"  v-model="mailContent.message"  name="" id="" cols="30" rows="10">
                </textarea>

                <vs-button :icon="success" :loading="sending" class="send" type="filled" gradient style="min-width: 60px" size="large" success animation-type="vertical">
                    <span v-if="!success">
                        Verzenden
                    </span>

                    <box-icon name='check' color="white" v-else></box-icon>

                    <template #animate>
                        <box-icon name='send' color="white"></box-icon>
                    </template>
                </vs-button>

                <!-- <span success>We zullen uw bericht zo snel mogelijk opvolgen!</span> -->
            </form>
            </div>
        </vs-col>
    </vs-row>
</template>

<script>

import emailjs from 'emailjs-com';

export default {
  
        data() {
            return {
                mailContent: {
                    fullName: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                },

                notificationIcon: "<box-icon name='info-circle'></box-icon>",
                success: false,
                sending: false,
            }
        },
      methods: {
        openNotification(position = null, color, icon) {
            const noti = this.$vs.notification({
                duration: 10000,
                flat: true,
                color,
                position,
                icon,
                progress: 'auto',
                title: 'Wij hebben uw bericht ontvangen 👍',
                text: `Wij zullen uw e-mail zo snel mogelijk opvolgen!`
            })
            },

        sendMail() {
            this.sending = true;
            emailjs.send('service_mdelectro', 'template_mdelectro', this.mailContent, 'user_qXmHNJ2W3etWUiV34ubiR')
                .then((result) => {
                    console.log('SUCCESS!', result.status, result.text);
                    this.sending = false;
                    this.success = true;
                        
                    this.openNotification(null, 'primary', this.notificationIcon)
                }, (error) => {
                    console.log('FAILED...', error);
                    this.sending = false;
                    this.success = false;
                });

            }
      },
      
    }
</script>


<style lang="sass">

form
    max-width: 420px
    margin: 30px auto
    background: white
    text-align: left
    padding: 40px
    border-radius: 10px

.vs-input-parent
    width: 100% 
    margin-top:10px !important

.vs-input-parent
    padding-bottom:15px !important 
        
.vs-input
    width: 100%
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px

.vs-button
    margin-top: 20px
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px

.no-bg
    background-color: transparent

   

</style>