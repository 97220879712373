<template>
    <div>
        <ContactForm/>

    </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'

    export default {
        components: { 
            ContactForm 
        },
        
    }
</script>

<style lang="sass" scoped>

</style>